<template>
<admin-template-component>
    <span>
      <h1>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</h1>
    </span>
</admin-template-component>
</template>

<script>
import AdminTemplateComponent from '@/views/templates/AdminTemplateComponent'

export default {
  name: 'Home',
  data () {
    return {}
  },
  components: {
    AdminTemplateComponent
  }
}
</script>

<style scoped>
</style>
