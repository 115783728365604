<template>
<v-app id="inspire">
    <v-navigation-drawer width="330" class="nav-drawer" stateless value="true" v-model="drawer" clipped app>
        <v-toolbar flat class="transparent">
            <v-list class="pa-0">
                <v-list-item>
                    <v-list-item-avatar>
                        <img v-if="usuario.empl_foto" :src="`${base_url}upload/employee/${usuario.empl_foto}`" />
                        <img v-if="!usuario.empl_foto" :src="`${base_url}upload/avatar.png`" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ usuario.empl_nome }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-toolbar>
        <v-list>
            <v-list-item class="dashboard" @click="goTo(dashboard[1])">
                <v-list-item-action>
                    <v-icon small color="lime lighten-1">mdi-monitor-dashboard</v-icon>
                </v-list-item-action>
                <v-list-item-title v-text="dashboard[0]" class="caption"></v-list-item-title>
            </v-list-item>

            <!-- SISTEMA -->
            <v-list-group>

                <template v-slot:activator>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon small color="lime lighten-1">mdi-image</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="caption">GERENCIAR SITE</v-list-item-title>
                    </v-list-item>
                </template>

                <v-list-item v-for="(sistema, i) in sistemas" :key="i" @click="goTo(sistema[2])" class="ml-7">
                    <v-list-item-action>
                        <v-icon small v-text="sistema[1]"></v-icon>
                    </v-list-item-action>
                    <v-list-item-title v-text="sistema[0]" class="caption"></v-list-item-title>
                </v-list-item>
            </v-list-group>

            <!-- BANNERS -->
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon small color="lime lighten-1">mdi-image</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="caption">GERENCIAR BANNERS</v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-for="(banner, i) in banners" :key="i" @click="goTo(banner[2])" class="ml-7">
                    <v-list-item-action>
                        <v-icon small v-text="banner[1]"></v-icon>
                    </v-list-item-action>
                    <v-list-item-title v-text="banner[0]" class="caption"></v-list-item-title>
                </v-list-item>
            </v-list-group>

            <!-- CONTEUDO -->
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon small color="lime lighten-1">mdi-file</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="caption">GERENCIAR CONTEÚDO</v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-for="(conteudo, i) in conteudos" :key="i" @click="goTo(conteudo[2])" class="ml-7">
                    <v-list-item-action>
                        <v-icon small v-text="conteudo[1]"></v-icon>
                    </v-list-item-action>
                    <v-list-item-title v-text="conteudo[0]" class="caption"></v-list-item-title>
                </v-list-item>
            </v-list-group>

            <!-- PRODUTOS -->
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon small color="lime lighten-1">mdi-layers</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="caption">GERENCIAR PRODUTOS</v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-for="(produto, i) in produtos" :key="i" @click="goTo(produto[2])" class="ml-7">
                    <v-list-item-action>
                        <v-icon small v-text="produto[1]"></v-icon>
                    </v-list-item-action>
                    <v-list-item-title v-text="produto[0]" class="caption"></v-list-item-title>
                </v-list-item>
            </v-list-group>

            <!-- LOJA -->
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon small color="lime lighten-1">mdi-cart</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="caption">GERENCIAR ECOMMERCE</v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-for="(ecommerce, i) in ecommerces" :key="i" @click="goTo(ecommerce[2])" class="ml-7">
                    <v-list-item-action>
                        <v-icon small v-text="ecommerce[1]"></v-icon>
                    </v-list-item-action>
                    <v-list-item-title v-text="ecommerce[0]" class="caption"></v-list-item-title>
                </v-list-item>
            </v-list-group>

            <!-- LINKS -->
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon small color="lime lighten-1">mdi-link</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="caption">GERENCIAR LINKS</v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-for="(link, i) in links" :key="i" @click="goTo(link[2])" class="ml-7">
                    <v-list-item-action>
                        <v-icon small v-text="link[1]"></v-icon>
                    </v-list-item-action>
                    <v-list-item-title v-text="link[0]" class="caption"></v-list-item-title>
                </v-list-item>
            </v-list-group>

            <!-- DOWNLOADS -->
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item>
                        <v-list-item-action>
                            <v-icon small color="lime lighten-1">mdi-cloud-download</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="caption">GERENCIAR DOWNLOADS</v-list-item-title>
                    </v-list-item>
                </template>
                <v-list-item v-for="(download, i) in downloads" :key="i" @click="goTo(download[2])" class="ml-7">
                    <v-list-item-action>
                        <v-icon small v-text="download[1]"></v-icon>
                    </v-list-item-action>
                    <v-list-item-title v-text="download[0]" class="caption"></v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
            <img src="/assets/img/logo_header.webp" alt="Direciona Websites" />
        </v-toolbar-title>
        <v-layout align-center justify-end row fill-height>
            <v-btn fab dark small outlined color="lime" @click="logout" class="mr-4">
                <v-icon dark>mdi-logout</v-icon>
            </v-btn>
        </v-layout>
    </v-app-bar>

    <v-main>
        <v-container class="fill-height" fluid>
            <v-layout justify-center>
                <v-flex>
                    <router-view />
                </v-flex>
            </v-layout>
        </v-container>
    </v-main>

    <v-footer app >
        <v-layout justify-center>
            <span>Direciona Web &copy; {{ new Date().getFullYear() }} | Versão:{{version}}</span>
        </v-layout>
    </v-footer>
</v-app>
</template>

<script>
import {
    URL_BASE,
    VERSION
} from '../../config/configs'

export default {
    data: () => ({
        base_url: URL_BASE,
        version: VERSION,
        usuario: {},
        drawer: true,
        dashboard: ['DASHBOARD', 'dashboard'],
        sistemas: [
            ['Gerenciar Empresa', 'mdi-arrow-right-thick', 'empresas'],
            ['Gerenciar Filiais', 'mdi-arrow-right-thick', 'filiais'],
            ['Departemento', 'mdi-arrow-right-thick', 'departamentos'],
            ['Funcionários', 'mdi-arrow-right-thick', 'funcionarios'],
            ['Newsletters', 'mdi-arrow-right-thick', 'newsletters']
        ],
        banners: [
            ['Setor', 'mdi-arrow-right-thick', 'banner-setores'],
            ['Banner', 'mdi-arrow-right-thick', 'banners']
        ],
        clientes: [
            ['Clientes', 'mdi-arrow-right-thick', 'clientes'],
            ['Representantes', 'mdi-arrow-right-thick', 'representantes']
        ],
        conteudos: [
            ['Tipos', 'mdi-arrow-right-thick', 'conteudo-tipos'],
            ['Seções', 'mdi-arrow-right-thick', 'conteudo-secoes'],
            ['Categorias', 'mdi-arrow-right-thick', 'conteudo-categorias'],
            ['Conteúdos', 'mdi-arrow-right-thick', 'conteudos']
        ],
        links: [
            ['Categorias', 'mdi-arrow-right-thick', 'links/categorias'],
            ['Links', 'mdi-arrow-right-thick', 'links']
        ],
        downloads: [
            ['Categorias', 'mdi-arrow-right-thick', 'downloads/categorias'],
            ['Arquivoss', 'mdi-arrow-right-thick', 'downloads']
        ],
        produtos: [
            ['Marcas', 'mdi-arrow-right-thick', 'produto/marcas'],
            ['Departamentos', 'mdi-arrow-right-thick', 'produto/departamentos'],
            ['Categorias', 'mdi-arrow-right-thick', 'produto/grupos'],
            ['Subcategorias', 'mdi-arrow-right-thick', 'produto/subgrupos'],
            ['Medidas', 'mdi-arrow-right-thick', 'produto/medidas'],
            ['Produtos', 'mdi-arrow-right-thick', 'produtos'],
            ['Descontos', 'mdi-arrow-right-thick', 'produtos/descontos'],
            ['Cores', 'mdi-arrow-right-thick', 'produtos/cores']
        ],
        ecommerces: [
            ['Usuários', 'mdi-arrow-right-thick', 'usuarios'],
            ['Pedidos', 'mdi-arrow-right-thick', 'loja/pedidos'],
            ['Categoria Lista de Presentes', 'mdi-arrow-right-thick', 'loja/lista/categorias'],
            ['Lista de Presentes', 'mdi-arrow-right-thick', 'loja/listas-de-casamento'],
            ['Cupom', 'mdi-arrow-right-thick', 'loja/cupons']
        ]
    }),
    props: {
        source: String
    },
    created() {
        this.$vuetify.theme.dark = true

        const user = this.$store.getters.getUsuario

        if (user) {
            this.usuario = this.$store.getters.getUsuario
        } else {
            this.$router.push({
                name: 'login'
            })
        }
    },
    methods: {
        logout() {
            this.$store.commit('SET_USER', null)
            sessionStorage.clear()
            this.$router.push({
                name: 'login'
            })
        },
        goTo(rota) {
            this.$router.push(`/${rota}`).catch(error => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
            //this.$router.push(`/${rota}`)
            // console.log(`/${rota}`);
        }
    },
    components: {}
}
</script>

<style scoped>
.dashboard {
    margin-left: 16px;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 5px;
}

.v-list-item-action {
    min-width: 30px;
}

.v-list-item {
    height: 35px;
    padding: 0 6px !important;
}
</style>
