import { render, staticRenderFns } from "./AdminTemplateComponent.vue?vue&type=template&id=22a6dfce&scoped=true"
import script from "./AdminTemplateComponent.vue?vue&type=script&lang=js"
export * from "./AdminTemplateComponent.vue?vue&type=script&lang=js"
import style0 from "./AdminTemplateComponent.vue?vue&type=style&index=0&id=22a6dfce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a6dfce",
  null
  
)

export default component.exports